window.addEventListener('heyflow-screen-view', (event) => {
    // console.log('heyflow screen view:', event.detail);
});

window.addEventListener('heyflow-submit', (event) => {
    // console.log('heyflow submit:', event.detail);
});

    (function(d, c){
        /* Controllable parameters */
        var time_out = 2000,
        hide_element = 'body',
        app_id = 5763;
        /* End controllable parameters */
  
        /* hide script */
        var a = d.createElement('style'),
            b = (hide_element ? hide_element : 'kw_none_hide_element') + '{opacity:0 !important;filter:alpha(opacity=0) !important;background:none !important;}',
            h = d.getElementsByTagName('head')[0];
        a.setAttribute('id', c);
        a.setAttribute('type', 'text/css');
        if (a.styleSheet) a.styleSheet.cssText = b;
        else a.appendChild(d.createTextNode(b));
        h.appendChild(a);
        d._kdl_hide_eid = c;
        d._kw_app_id = app_id;
        setTimeout(function(){var a = d.getElementById(c);
                          if (a) a.parentNode.removeChild(a);}, time_out);
  
        /* init */
        d._start_time = Date.now();
        var kw_dyn_js = d.createElement("script");
        var asset_id = d.location.host + "/" + d.location.pathname.replace(/\//g, '_');
        var kw_dyn_src = "https://kdl.keywee.co/" + asset_id + ".js";
        kw_dyn_js.setAttribute("src",kw_dyn_src);
        kw_dyn_js.setAttribute("type", "text/javascript");
        kw_dyn_js.setAttribute("async",true);
        var head = d.head;
        head.insertBefore(kw_dyn_js, head.lastElementChild);
      })(document, 'async-hide');